import { addTransitionForCssProperties, createStyles, mediaHelper } from '@affstore-ds/uikit'

const COPY_BUTTON = 'copyButton'

export const useManagerContactStyles = createStyles((_theme, _parameters, getReference) => ({
  contactWrapper: {
    ':hover': {
      [`& .${getReference(COPY_BUTTON)}`]: {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  text: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: '118px',
  },
  textCapitalize: {
    textTransform: 'capitalize',
  },
  avatar: {
    minWidth: '36px',
    width: '36px',
    height: '36px',
  },
  copyButton: {
    ref: getReference(COPY_BUTTON),
    visibility: 'hidden',
    opacity: 0,
    transition: addTransitionForCssProperties(['opacity']),

    [`${mediaHelper.md}`]: {
      visibility: 'visible',
      opacity: 1,
    },
  },
}))
