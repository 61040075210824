import { createStyles } from '@affstore-ds/uikit'

export const useDropdownStyles = createStyles(() => ({
  dropdown: {
    '&&': {
      minWidth: '230px',
      left: 'auto !important',
    },
  },
}))
