import { EmotionCSSObject, MantineTheme } from '@affstore-ds/uikit'

export function getTagifyGlobalStyles(theme: MantineTheme): EmotionCSSObject {
  return {
    '.tagify__dropdown': {
      borderRadius: theme.other.borderRadius[8],
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 3px, rgba(0, 0, 0, 0.05) 0px 10px 15px -5px, rgba(0, 0, 0, 0.04) 0px 7px 7px -5px !important',
    },
    '.tagify__dropdown__wrapper': {
      padding: theme.other.spacings[12],
    },
    '.tagify__dropdown__item': {
      color: theme.other?.colors.greyIron[19],
    },
    '.tagify__dropdown__item--active': {
      color: theme.other?.colors.greyIron[18],
      background: theme.other?.colors.greyIron[3],
    },
    '.tagify__dropdown[position=text] .tagify__dropdown__wrapper': {
      borderWidth: 0,
    },
    '.tagify': {
      '--tags-focus-border-color': theme.other.colors.greyIron[7],
      '--tags-hover-border-color': theme.other.colors.greyIron[7],
      '--tags-border-color': theme.other.colors.greyIron[7],
      borderRadius: `0px 0px ${theme.other.borderRadius[8]} ${theme.other.borderRadius[8]}`,
    },
    '--tagify-dd-color-primary': theme.other?.colors.greyIron[3],
    '--tagify-dd-item-pad': `${theme.other.borderRadius[8]} ${theme.other.borderRadius[12]}`,
  }
}
