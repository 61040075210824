import React, { FC } from 'react'
import { HelmetProvider } from 'react-helmet-async'

export const withHelmet = (Component: FC) => {
  const HOCWithHelmet: FC = () => (
    <HelmetProvider>
      <Component />
    </HelmetProvider>
  )

  HOCWithHelmet.displayName = 'HOCWithHelmet'

  return HOCWithHelmet
}
