import React, { FC, memo, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { I18nProvider } from '@affiliate-cabinet/i18n'

import { getBrandConfig } from '../../config/brands'

const { brandConfig } = getBrandConfig()

export const withI18n = (Component: FC) => {
  const HOCWithI18n: FC = memo(() => {
    const [search] = useSearchParams()

    const versions = useMemo(
      () =>
        brandConfig.translationProjects.reduce(
          (accumulator, project) => {
            accumulator[project.alias] = search.get(`version${project.alias}`) || undefined

            return accumulator
          },
          {} as Record<string, string | undefined>,
        ),
      [search],
    )
    const keysAsValues = useMemo(() => Boolean(search.has('keysAsValues') && search.get('keysAsValues') !== undefined), [search])
    const projects = useMemo(() => brandConfig.translationProjects.map((project) => ({ ...project, version: versions[project.alias] })), [versions])

    return (
      <I18nProvider projects={projects} keysAsValues={keysAsValues} allowedLocales={brandConfig.languages}>
        <Component />
      </I18nProvider>
    )
  })

  HOCWithI18n.displayName = 'HOCWithI18n'

  return HOCWithI18n
}
