import React, { FC, useMemo } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Loader, Select, SelectPropertiesInterface } from '@affstore-ds/uikit'

import { linkTypesEntity } from '../../entities/constants/linkTypesEntity'

import { usePromoBannersByIdWithLandings } from '../../entities/hooks/usePromoBannersByIdWithLandings'

import { useAdvertiser } from '../../entities/advertiser'
import { commonTranslationKeys } from '../../shared/translations/common.translationKeys'
import { selectTranslationKeys } from '../../shared/translations/select.translationKeys'

import { promoBannerLandingsPageLinksSelectTranslationKeys } from './translations/promoBannerLandingsPageLinksSelect.translationKeys'

export const PromoBannerLandingsPageLinksSelect: FC<
  Omit<SelectPropertiesInterface, 'data'> & { advertiserId: number; bannerId: number; locale: string }
> = ({ label, disabled, advertiserId, bannerId, locale, ...rest }) => {
  const { i18n } = useI18n()
  const { advertiser, loading: asvertiserIsFetching } = useAdvertiser(advertiserId)
  const { landings, landingsIsFetching } = usePromoBannersByIdWithLandings(bannerId)

  const PREPARED_LANDINGS_LINKS = useMemo(() => {
    const advertiserLinks = (advertiser?.links || []).map((link) => ({
      value: link.url,
      label: i18n.t(i18n.t(linkTypesEntity.linkTypeToTranslationKeys[link.type].labelTKey)),
      'data-testid': `link-item-${link.type}`,
    }))

    const landingLinks = landings.map((landing) => ({
      value: landing.links[locale]?.link || landing.links['en_US']?.link,
      label: landing.titles[locale] || landing.titles['en_US'],
      'data-testid': `link-item-${landing.links[locale]?.linkId || landing.links['en_US']?.linkId}`,
    }))

    return [...advertiserLinks, ...landingLinks]
  }, [advertiser?.links, i18n, landings, locale])

  return (
    <Select
      clearable={false}
      data={PREPARED_LANDINGS_LINKS || []}
      label={label || i18n.t(promoBannerLandingsPageLinksSelectTranslationKeys.promoLandingLabel)}
      data-testid="landing-links-select"
      placeholder={i18n.t(selectTranslationKeys.selectPlaceholder)}
      nothingFound={i18n.t(commonTranslationKeys.commonNoData)}
      disabled={disabled || asvertiserIsFetching || landingsIsFetching}
      rightSection={(asvertiserIsFetching || landingsIsFetching) && <Loader style={{ height: '20px', width: '20px' }} />}
      {...rest}
    />
  )
}
