import { createStyles } from '@affstore-ds/uikit'

export const useGuestStyles = createStyles((theme, __, getReference) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
    minHeight: theme.other.viewport.height,
    boxSizing: 'border-box',
  },
  main: {
    ref: getReference('main'),
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    'p, span, li': {
      '& > a': {
        fontSize: 'inherit !important',
      },
    },
  },
  mainWrapper: {
    display: 'flex',
    width: '100%',
    [`& .${getReference('main')}`]: {
      flex: 'unset',
      width: '100%',
    },
  },
}))
