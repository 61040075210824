import { createStyles } from '@affstore-ds/uikit'

const referenceIcon = 'NavMenuButtonLogoutIcon'

export const useNavMenuButtonLogoutStyles = createStyles((theme, _, getReference) => ({
  root: {
    border: 'none !important',
    [`&:disabled .${getReference(referenceIcon)}`]: {
      color: 'currentcolor',
    },
    padding: '8px 12px',
  },
  inner: {
    justifyContent: 'space-between',
    color: theme.other.colors.greyIron[18],
  },
  icon: {
    ref: getReference(referenceIcon),
    color: theme.other.colors.brand[13],
  },
}))
