import React, { FC, memo } from 'react'

import { RestQueryClientProvider } from '@affiliate-cabinet/rest'

export const withRestQueryClient = (Component: FC, withDevelopmentTools: boolean) => {
  const HOCRestQueryClient: FC = memo(() => (
    <RestQueryClientProvider withDevelopmentTools={withDevelopmentTools}>
      <Component />
    </RestQueryClientProvider>
  ))

  HOCRestQueryClient.displayName = 'HOCRestQueryClient'

  return HOCRestQueryClient
}
