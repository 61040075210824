import React, { FC } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { NavigationContainerProvider } from '../../shared/providers/NavigationContainer'

export const withRouter = (Component: FC) => {
  const HOCWithRouter: FC = () => (
    <BrowserRouter>
      <NavigationContainerProvider>
        <Component />
      </NavigationContainerProvider>
    </BrowserRouter>
  )

  HOCWithRouter.displayName = 'HOCWithRouter'

  return HOCWithRouter
}
