import { createStyles } from '@affstore-ds/uikit'

export const useVideoLoaderStyles = createStyles(() => ({
  container: {
    position: 'absolute',
    top: '-24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 490,
    height: 304,
    zIndex: -100,
  },
}))
