import { createStyles } from '@affstore-ds/uikit'

export const usePromoLandingsFiltersStyles = createStyles((theme) => ({
  contentWrapper: {
    backgroundColor: theme.other.colors.white[0],
    border: '1px solid',
    borderRadius: theme.other.borderRadius[8],
    borderColor: theme.other.colors.greyIron[4],
  },
  filterWrapper: {
    columnGap: '20px',
    rowGap: '20px',
  },
}))
