import React, { FC } from 'react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Button, ButtonVariantEnum, Group, LinkText, Modal, Stack, Text, Title } from '@affstore-ds/uikit'

import { LegalNoticeModalPropertiesInterface } from './LegalNoticeModal.types'

import { getBrandConfig } from '../../../../../../../../config/brands'
// TODO: remove import from pages on this layer
import { signUpTranslationKeys } from '../../../../../../../../pages/auth/signup/translations/SignUp.translationKeys'
import { transferPartnerTranslationKeys } from '../../translations/TransferPartner.translationKeys'

import { useLegalNoticeModalStyles } from './LegalNoticeModal.styled'

const { brandConfig } = getBrandConfig()

export const LegalNoticeModal: FC<LegalNoticeModalPropertiesInterface> = ({ opened, onClose, onAccept }) => {
  const { i18n } = useI18n()
  const { classes } = useLegalNoticeModalStyles()

  return (
    <Modal
      centered
      hideHeader
      hideOpenButton
      size="540px"
      elementVariant="default"
      classNames={{ children: classes.children, modal: classes.modal }}
      opened={opened}
      onClose={onClose}
    >
      <Stack spacing={8}>
        <Title elementSize="l" order={3}>
          {i18n.t(transferPartnerTranslationKeys.legalNoticeModalTitle)}
        </Title>
        <Text elementVariant="default" elementSize="s">
          {i18n.t(transferPartnerTranslationKeys.legalNoticeModalDescriptionPart1)}{' '}
          <LinkText href={brandConfig.termsAndConditionUrl} target="_blank" rel="noreferrer noopener" className={classes.link}>
            {i18n.t(signUpTranslationKeys.authFormTermsAndConditionsLink)}
          </LinkText>
          {', '}
          <LinkText href={brandConfig.privacyPolicyUrl} target="_blank" rel="noreferrer noopener" className={classes.link}>
            {i18n.t(signUpTranslationKeys.authFormPrivacyNotice)}
          </LinkText>
          {', '}
          {i18n.t(transferPartnerTranslationKeys.legalNoticeModalDescriptionPart2)}
        </Text>
        <Text elementVariant="default" elementSize="s" pt={7}>
          {i18n.t(transferPartnerTranslationKeys.legalNoticeModalDescriptionPart3)}
        </Text>
      </Stack>
      <Group className={classes.buttonGroup} spacing={12}>
        <Button elementVariant={ButtonVariantEnum.SecondaryMainLine} onClick={onClose}>
          {i18n.t(transferPartnerTranslationKeys.legalNoticeModalCloseButton)}
        </Button>
        <Button onClick={onAccept}>{i18n.t(transferPartnerTranslationKeys.legalNoticeModalAcceptButton)}</Button>
      </Group>
    </Modal>
  )
}
