import React, { FC, memo } from 'react'

import { AuthProvider } from '@affiliate-cabinet/auth'

import { routes } from '../../shared/routes'

export const withAuth = (Component: FC) => {
  const HOCWithAuth: FC = memo(() => (
    <AuthProvider ROUTES_SHARED_VALUES={routes.ROUTES_SHARED_VALUES} ROUTES_ERROR={routes.ROUTES_ERROR} signInRoute={routes.ROUTES_AUTH.signIn}>
      <Component />
    </AuthProvider>
  ))

  HOCWithAuth.displayName = 'HOCWithAuth'

  return HOCWithAuth
}
