import React, { FC, Suspense, useEffect, useMemo } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { useAuth } from '@affiliate-cabinet/auth'
import { Container, FixedLayout, Loader } from '@affstore-ds/uikit'

import { useFrom } from '../../../shared/hooks/useFrom'

import { routes } from '../../../shared/routes'

import { GuestSkeleton } from './components/GuestSkeleton'
import { useGuestStyles } from './Guest.styled'

export const Guest: FC = () => {
  const { classes } = useGuestStyles()
  const location = useLocation()

  const {
    auth: { user, isUserLoading },
  } = useAuth()

  const { returnToFromReactRouter, returnToFromURLSearch } = useFrom(routes.ROUTES_DASHBOARD.dashboard)

  const isActivate = useMemo(() => {
    const [locationEmpty, locationAuth, locationActivate, locationToken] = location.pathname.split('/')
    const [routesEmpty, routesAuth, routesActivate] = routes.ROUTES_AUTH.activate.split('/')

    return locationEmpty === routesEmpty && locationAuth === routesAuth && locationActivate === routesActivate && Boolean(locationToken)
  }, [location.pathname])

  const is2FA = useMemo(() => {
    const [locationEmpty, locationAuth, locationTwoFA] = location.pathname.split('/')
    const [routesEmpty, routesAuth, routesTwoFA] = routes.ROUTES_AUTH.twoFA.split('/')

    return locationEmpty === routesEmpty && locationAuth === routesAuth && locationTwoFA === routesTwoFA
  }, [location.pathname])

  const renderRedirectFromReactRouter = () => user && (returnToFromURLSearch ? <Loader /> : <Navigate to={returnToFromReactRouter} />)

  useEffect(() => {
    if (user && returnToFromURLSearch) {
      window.location.href = returnToFromURLSearch
    }
  }, [returnToFromURLSearch, user])

  return (
    <FixedLayout>
      <Container fluid className={classes.container}>
        <main className={classes.main}>
          {isActivate || is2FA ? null : renderRedirectFromReactRouter()}
          <Suspense fallback={<GuestSkeleton />}>{isActivate || is2FA || (!user && !isUserLoading) ? <Outlet /> : null}</Suspense>
        </main>
      </Container>
    </FixedLayout>
  )
}
