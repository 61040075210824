import compose from 'compose-function'

import { withMantine, withNotifications } from '@affstore-ds/uikit'

import { getBrandConfig } from '../../config/brands'
import { getTagifyGlobalStyles } from '../../shared/styles/tagifyGlobalStyles'

import { withAnalytics } from './withAnalytics'
import { withApollo } from './withApollo'
import { withAuth } from './withAuth'
import { withErrorBoundary } from './withErrorBoundary'
import { withHelmet } from './withHelmet'
import { withI18n } from './withI18n'
import { withRestQueryClient } from './withRestQueryClient'
import { withRouter } from './withRouter'
import { withTracker } from './withTracker'

const { brandConfig } = getBrandConfig()

export const withProviders = compose(
  (Component) =>
    withMantine(Component, {
      defaultColorScheme: brandConfig.theme?.defaultColorScheme,
      colorThemes: brandConfig.theme?.defaultColorScheme ? { [brandConfig.theme.defaultColorScheme]: brandConfig.theme?.colorTheme } : {},
      getGlobalBodyStyles: getTagifyGlobalStyles,
    }),
  withNotifications,
  withRouter,
  withHelmet,
  withRestQueryClient,
  withApollo,
  withAuth,
  withI18n,
  withAnalytics,
  withTracker,
  withErrorBoundary, // withErrorBoundary must be included at the end, because it uses some implementations of other providers (e.g. useNavigate from withRouter)
)
