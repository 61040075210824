import React, { FC, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { useI18n } from '@affiliate-cabinet/i18n'
import { Button, ButtonVariantEnum, Group } from '@affstore-ds/uikit'

import { ErrorPage } from '../../shared/components/ErrorPage'
import { errorPageTranslationKeys } from '../../shared/translations/errorPage.translationKeys'

export const withErrorBoundary = (Component: FC) => {
  const HOCWithErrorBoundary: FC = () => {
    const [hasError, setHasError] = useState<Error | null>(null)
    const { i18n } = useI18n()
    const navigate = useNavigate()
    const location = useLocation()

    return (
      <Sentry.ErrorBoundary
        key={hasError ? location.pathname : hasError}
        onError={setHasError}
        fallback={({ error, componentStack, resetError }) => (
          <ErrorPage
            title={i18n.t(errorPageTranslationKeys.errorPage500Title)}
            description={
              <>
                {i18n.t(errorPageTranslationKeys.errorPage500Description)}
                {import.meta.env.DEV && (
                  <div style={{ maxHeight: '200px', overflow: 'auto' }}>
                    <div>{error.toString()}</div>
                    <div>{componentStack}</div>
                  </div>
                )}
              </>
            }
          >
            <Group mt={24}>
              <Button
                elementVariant={ButtonVariantEnum.SecondaryMain}
                onClick={() => {
                  /* Navigate to previous page if history has data */
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  navigate(history.length > 2 ? -1 : '/')
                  setHasError(null)
                }}
              >
                {i18n.t(errorPageTranslationKeys.errorPageButtonPreviousPage)}
              </Button>
              <Button
                onClick={() => {
                  {
                    /* When resetError() is called it will remove the Fallback component
                      and render the Sentry ErrorBoundary's children in their initial state */
                  }
                  resetError()
                  setHasError(null)
                }}
              >
                {i18n.t(errorPageTranslationKeys.errorPageButtonReload)}
              </Button>
            </Group>
          </ErrorPage>
        )}
      >
        <Component />
      </Sentry.ErrorBoundary>
    )
  }

  HOCWithErrorBoundary.displayName = 'HOCWithErrorBoundary'

  return HOCWithErrorBoundary
}
