import React, { FC, useEffect } from 'react'

import { useAuth } from '@affiliate-cabinet/auth'
import { resolveCookieDomain, resolveDomain } from '@affiliate-cabinet/rest'
import { checkParametersAndSendHit, setWrittenCookies } from '@affiliate-frontend-analytics/tracker'

import { getBrandConfig } from '../../config/brands'
import { OnboardingToOfferConversionFunnel, setReferrerToCookies } from '../../shared/utils'

const { brandConfig } = getBrandConfig()

export const withTracker = (Component: FC) => {
  const HOCWithTracker: FC = () => {
    const {
      auth: { user, isUserLoading },
    } = useAuth()

    const cookieDomain = resolveCookieDomain()
    const apiDomain = resolveDomain()

    // Set the referrer and marketing cookies only once when page loaded
    useEffect(() => {
      setReferrerToCookies()
      OnboardingToOfferConversionFunnel.setMarketingURLParametersToCookies()
    }, [])

    useEffect(() => {
      setWrittenCookies(cookieDomain)

      if (!user && !isUserLoading) {
        checkParametersAndSendHit(brandConfig.siteId, apiDomain)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUserLoading, user])

    return <Component />
  }

  HOCWithTracker.displayName = 'HOCWithTracker'

  return HOCWithTracker
}
